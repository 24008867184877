import React from 'react'

const PaymentInterface = (props) => {
    

    return (
        <div>
            <h1>This is Payment PaymentInterface</h1>
        </div>
    )
}

export default PaymentInterface
