import React from 'react'
import { Link } from 'react-router-dom'
import '../../static/banner.css'

const UspBox = () => {
  return (
    <div className='d-flex justify-content-center uspBox-main' >
      <div className="UspBox d-flex justify-content-center align-items-center flex-column py-4" style={{ width: "85%", backgroundColor: "#fff", position: "relative", borderRadius: "8px", boxShadow: "2px 2px 30px #808080" }}>
        <div className="uspTitle my-1 d-flex justify-content-center align-items-center" style={{ flexBasis: "15%", height: "100%" }}>
          <h2 className='uspTitle-desk' style={{ fontFamily: "Clash Display", fontWeight:"400", fontSize:"34px" }}>Get Your E-Visa in 3 Steps !</h2>
          <h2 className='uspTitle-mob px-2' style={{ fontFamily: "Clash Display", fontWeight:"500", fontSize:"30px" }}>Get Your E-Visa in 3 Steps !</h2>
        </div>
        <div className="uspContent my-1 d-flex justify-content-center align-items-center" style={{ flexBasis: "70%", height: "100%", width: "90%" }}>
          <div className="uc-b1 mx-1 d-flex justify-content-center align-items-center flex-column" style={{ flexBasis: "33%", height: "100%", width: "100%" }}>
            <div className="ucb1 my-1 d-flex justify-content-center align-items-center" style={{ flexBasis: "40%", height: "100%", width: "100%" }}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5625 25.3405V24.2466V9.65625C9.5625 7.84686 11.0344 6.375 12.8438 6.375H20.5V12.2155C20.5 12.5177 20.6222 12.7911 20.8201 12.989C21.018 13.1868 21.2915 13.3092 21.5938 13.3092C22.1983 13.3092 22.6875 12.82 22.6875 12.2155V11.1216V7.92163L24.2875 9.5216C24.5011 9.73522 24.781 9.8421 25.0609 9.8421C25.3407 9.8421 25.6205 9.73529 25.8342 9.52167C26.2614 9.09442 26.2614 8.40228 25.8342 7.97504L22.3671 4.50793C22.1581 4.29524 21.8782 4.21834 21.5938 4.1875H12.8438C9.82846 4.1875 7.375 6.64096 7.375 9.65625V25.3405C7.375 25.945 7.8642 26.4342 8.46875 26.4342C9.0733 26.4342 9.5625 25.945 9.5625 25.3405Z" fill="#0A1D26" />
                <path d="M35.1431 4.85706C34.7159 4.42981 34.0237 4.42981 33.5965 4.85706L27.3854 11.0681C27.3845 11.0691 27.3831 11.0693 27.3822 11.0703C27.3813 11.0712 27.381 11.0726 27.3801 11.0735L23.0119 15.4417C23.0091 15.4445 23.0064 15.4472 23.0036 15.45L8.06828 30.3853C8.065 30.3887 8.06167 30.3917 8.0584 30.3952L5.85754 32.596C5.4303 33.0233 5.4303 33.7154 5.85754 34.1426C6.07117 34.3563 6.35101 34.4631 6.63086 34.4631C6.91071 34.4631 7.19055 34.3563 7.40417 34.1426L8.67443 32.8724C9.59087 33.9611 10.9076 34.6631 12.3634 34.7846C12.5258 34.8081 12.6775 34.8123 12.843 34.8123H28.1555C31.1708 34.8123 33.6243 32.3589 33.6243 29.3436V16.2186C33.594 15.9341 33.5162 15.6542 33.3038 15.4453L29.7027 11.8441L35.1431 6.40369C35.5704 5.97644 35.5704 5.2843 35.1431 4.85706ZM28.1555 32.6248H12.843C12.7907 32.6248 12.7415 32.6302 12.6828 32.6206C12.6465 32.6142 12.6102 32.6088 12.5739 32.6067C11.631 32.5371 10.7946 32.0485 10.2404 31.3065L24.2345 17.3123H31.4368V29.3436C31.4368 31.153 29.9649 32.6248 28.1555 32.6248ZM26.422 15.1248L28.1561 13.3908L29.8901 15.1248H26.422Z" fill="#0A1D26" />
              </svg>


            </div>
            <div className="ucb2 d-flex justify-content-center align-items-center" style={{ flexBasis: "20%", height: "100%", width: "100%" }}>
              <h3 style={{ fontFamily: "Clash Display", fontSize: "24px", fontWeight: "500" }}>Paperless Process</h3>
            </div>
            <div className="ucb3 d-flex justify-content-center align-items-center" style={{ flexBasis: "30%", height: "100%", width: "90%", textAlign: "center" }}>
              <h6 style={{ fontSize: "16px", lineHeight: "26px", fontWeight: "500" }}>Maximise convenience with no physical document submissions.</h6>
            </div>
          </div>
          <div className='mx-5 uspLine' style={{ height: "75%", width: "1px", backgroundColor: "#000" }}></div>
          <div className="uc-b2 mx-1 d-flex justify-content-center align-items-center flex-column" style={{ flexBasis: "33%", height: "100%", width: "100%" }}>
            <div className="ucb1 my-1 d-flex justify-content-center align-items-center" style={{ flexBasis: "40%", height: "100%", width: "100%" }}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.5008 8.375V6.25C27.5008 5.25544 27.1057 4.30161 26.4024 3.59835C25.6991 2.89509 24.7453 2.5 23.7508 2.5H21.2508C20.2562 2.5 19.3024 2.89509 18.5991 3.59835C17.8958 4.30161 17.5008 5.25544 17.5008 6.25V8.375C14.3921 9.46837 11.73 11.555 9.92576 14.3125C9.83547 14.4504 9.77323 14.6047 9.74259 14.7667C9.71194 14.9286 9.7135 15.095 9.74716 15.2563C9.81514 15.5822 10.0098 15.8677 10.2883 16.05C10.5667 16.2323 10.9062 16.2966 11.2321 16.2286C11.5579 16.1606 11.8434 15.966 12.0258 15.6875C13.3313 13.6802 15.1859 12.0902 17.369 11.1064C19.5521 10.1227 21.9718 9.7867 24.3403 10.1384C26.7089 10.4901 28.9265 11.5147 30.7296 13.0903C32.5327 14.6659 33.8452 16.7263 34.5112 19.0263C35.1772 21.3263 35.1685 23.7692 34.4862 26.0645C33.8039 28.3597 32.4768 30.4107 30.6626 31.9735C28.8484 33.5363 26.6235 34.5451 24.2525 34.88C21.8816 35.2149 19.4643 34.8617 17.2883 33.8625C16.9866 33.7249 16.6426 33.7128 16.332 33.8289C16.0214 33.9449 15.7696 34.1796 15.632 34.4812C15.4944 34.7829 15.4823 35.1269 15.5984 35.4375C15.7144 35.7481 15.9491 35.9999 16.2508 36.1375C18.0957 37.0052 20.0981 37.4876 22.1358 37.5554C24.1734 37.6232 26.2035 37.2749 28.102 36.5318C30.0005 35.7887 31.7276 34.6663 33.1778 33.2333C34.628 31.8003 35.7708 30.0867 36.5365 28.1971C37.3022 26.3076 37.6746 24.2818 37.6311 22.2435C37.5876 20.2052 37.129 18.1971 36.2834 16.342C35.4377 14.4869 34.2228 12.8236 32.7127 11.4538C31.2027 10.084 29.4293 9.03641 27.5008 8.375ZM20.0008 7.7375V6.25C20.0008 5.91848 20.1325 5.60054 20.3669 5.36612C20.6013 5.1317 20.9192 5 21.2508 5H23.7508C24.0823 5 24.4002 5.1317 24.6346 5.36612C24.8691 5.60054 25.0008 5.91848 25.0008 6.25V7.725C24.1752 7.58028 23.3389 7.50502 22.5008 7.5C21.6623 7.50973 20.8261 7.58917 20.0008 7.7375Z" fill="#0A1D26" />
                <path d="M29.725 16.7245C29.5188 16.467 29.219 16.3014 28.8912 16.2639C28.5634 16.2265 28.234 16.3202 27.975 16.5245L24.4625 19.3245C23.8774 18.9479 23.1959 18.7482 22.5 18.7495C21.7583 18.7495 21.0333 18.9695 20.4166 19.3815C19.7999 19.7936 19.3193 20.3793 19.0355 21.0645C18.7516 21.7497 18.6774 22.5037 18.8221 23.2311C18.9668 23.9586 19.3239 24.6267 19.8484 25.1512C20.3728 25.6756 21.041 26.0328 21.7684 26.1775C22.4958 26.3222 23.2498 26.2479 23.9351 25.9641C24.6203 25.6803 25.206 25.1996 25.618 24.5829C26.0301 23.9662 26.25 23.2412 26.25 22.4995C26.2484 22.073 26.1722 21.6499 26.025 21.2495L29.525 18.4495C29.7754 18.244 29.9361 17.9492 29.9734 17.6274C30.0108 17.3057 29.9217 16.9819 29.725 16.7245ZM22.5 23.7495C22.2528 23.7495 22.0111 23.6762 21.8055 23.5389C21.6 23.4015 21.4398 23.2063 21.3452 22.9779C21.2505 22.7495 21.2258 22.4982 21.274 22.2557C21.3223 22.0132 21.4413 21.7905 21.6161 21.6157C21.7909 21.4408 22.0137 21.3218 22.2561 21.2736C22.4986 21.2253 22.7499 21.2501 22.9784 21.3447C23.2068 21.4393 23.402 21.5995 23.5393 21.8051C23.6767 22.0106 23.75 22.2523 23.75 22.4995C23.75 22.8311 23.6183 23.149 23.3839 23.3834C23.1495 23.6178 22.8315 23.7495 22.5 23.7495Z" fill="#0A1D26" />
                <path d="M13.75 18.75H6.25C5.91848 18.75 5.60054 18.8817 5.36612 19.1161C5.1317 19.3505 5 19.6685 5 20C5 20.3315 5.1317 20.6495 5.36612 20.8839C5.60054 21.1183 5.91848 21.25 6.25 21.25H13.75C14.0815 21.25 14.3995 21.1183 14.6339 20.8839C14.8683 20.6495 15 20.3315 15 20C15 19.6685 14.8683 19.3505 14.6339 19.1161C14.3995 18.8817 14.0815 18.75 13.75 18.75Z" fill="#0A1D26" />
                <path d="M13.75 23.75H3.75C3.41848 23.75 3.10054 23.8817 2.86612 24.1161C2.6317 24.3505 2.5 24.6685 2.5 25C2.5 25.3315 2.6317 25.6495 2.86612 25.8839C3.10054 26.1183 3.41848 26.25 3.75 26.25H13.75C14.0815 26.25 14.3995 26.1183 14.6339 25.8839C14.8683 25.6495 15 25.3315 15 25C15 24.6685 14.8683 24.3505 14.6339 24.1161C14.3995 23.8817 14.0815 23.75 13.75 23.75Z" fill="#0A1D26" />
                <path d="M13.75 28.75H6.25C5.91848 28.75 5.60054 28.8817 5.36612 29.1161C5.1317 29.3505 5 29.6685 5 30C5 30.3315 5.1317 30.6495 5.36612 30.8839C5.60054 31.1183 5.91848 31.25 6.25 31.25H13.75C14.0815 31.25 14.3995 31.1183 14.6339 30.8839C14.8683 30.6495 15 30.3315 15 30C15 29.6685 14.8683 29.3505 14.6339 29.1161C14.3995 28.8817 14.0815 28.75 13.75 28.75Z" fill="#0A1D26" />
              </svg>



            </div>
            <div className="ucb2 d-flex justify-content-center align-items-center" style={{ flexBasis: "20%", height: "100%", width: "100%" }}>
              <h3 style={{ fontFamily: "Clash Display", fontSize: "24px", fontWeight: "500" }}>Quick Processing</h3>
            </div>
            <div className="ucb3 d-flex justify-content-center align-items-center" style={{ flexBasis: "30%", height: "100%", width: "100%", textAlign: "center" }}>
              <h6 style={{ fontSize: "16px", lineHeight: "26px", fontWeight: "500" }}>Start exploring sooner with our speedy digital visa processing.  </h6>
            </div>
          </div>
          <div className='mx-5 uspLine' style={{ height: "75%", width: "1px", backgroundColor: "#000" }}></div>
          <div className="uc-b3 mx-1 d-flex justify-content-center align-items-center flex-column" style={{ flexBasis: "33%", height: "100%", width: "100%" }}>
            <div className="ucb1 my-1 d-flex justify-content-center align-items-center" style={{ flexBasis: "40%", height: "100%", width: "100%" }}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.15 9.27541L24.275 2.91291C23.107 1.84568 21.5821 1.25391 20 1.25391C18.4179 1.25391 16.893 1.84568 15.725 2.91291L8.85 9.27541C8.04874 9.84055 7.39455 10.5894 6.94219 11.4594C6.48983 12.3293 6.25247 13.2949 6.25 14.2754V32.5879C6.273 34.2303 6.94161 35.7976 8.11123 36.9509C9.28085 38.1041 10.8574 38.7506 12.5 38.7504H27.5C29.1426 38.7506 30.7191 38.1041 31.8888 36.9509C33.0584 35.7976 33.727 34.2303 33.75 32.5879V14.2754C33.7475 13.2949 33.5102 12.3293 33.0578 11.4594C32.6055 10.5894 31.9513 9.84055 31.15 9.27541ZM31.25 32.5879C31.2271 33.5672 30.822 34.4987 30.1212 35.1832C29.4204 35.8676 28.4796 36.2507 27.5 36.2504H12.5C11.5204 36.2507 10.5796 35.8676 9.87881 35.1832C9.17801 34.4987 8.77286 33.5672 8.75 32.5879V14.2754C8.74886 13.6801 8.89283 13.0936 9.16945 12.5665C9.44606 12.0394 9.84698 11.5877 10.3375 11.2504L10.475 11.1379L17.425 4.75041C18.128 4.10655 19.0467 3.74941 20 3.74941C20.9533 3.74941 21.872 4.10655 22.575 4.75041L29.525 11.1629L29.6625 11.2754C30.1495 11.6103 30.5483 12.058 30.8247 12.5804C31.1012 13.1028 31.2471 13.6844 31.25 14.2754V32.5879Z" fill="#0A1D26" />
                <path d="M20 8.75C19.1347 8.75 18.2888 9.00659 17.5694 9.48732C16.8499 9.96805 16.2892 10.6513 15.958 11.4508C15.6269 12.2502 15.5403 13.1299 15.7091 13.9785C15.8779 14.8272 16.2946 15.6067 16.9064 16.2186C17.5183 16.8304 18.2978 17.2471 19.1465 17.4159C19.9951 17.5847 20.8748 17.4981 21.6742 17.167C22.4737 16.8358 23.1569 16.2751 23.6377 15.5556C24.1184 14.8362 24.375 13.9903 24.375 13.125C24.375 11.9647 23.9141 10.8519 23.0936 10.0314C22.2731 9.21094 21.1603 8.75 20 8.75ZM20 15C19.6292 15 19.2667 14.89 18.9583 14.684C18.65 14.478 18.4096 14.1851 18.2677 13.8425C18.1258 13.4999 18.0887 13.1229 18.161 12.7592C18.2334 12.3955 18.412 12.0614 18.6742 11.7992C18.9364 11.537 19.2705 11.3584 19.6342 11.286C19.9979 11.2137 20.3749 11.2508 20.7175 11.3927C21.0601 11.5346 21.353 11.775 21.559 12.0833C21.765 12.3916 21.875 12.7542 21.875 13.125C21.875 13.6223 21.6775 14.0992 21.3258 14.4508C20.9742 14.8025 20.4973 15 20 15Z" fill="#0A1D26" />
              </svg>


            </div>
            <div className="ucb2 d-flex justify-content-center align-items-center" style={{ flexBasis: "20%", height: "100%", width: "100%" }}>
              <h3 style={{ fontFamily: "Clash Display", fontSize: "24px", fontWeight: "500" }}>Great Prices</h3>
            </div>
            <div className="ucb3 d-flex justify-content-center align-items-center" style={{ flexBasis: "30%", height: "100%", width: "80%", textAlign: "center" }}>
              <h6 style={{ fontSize: "16px", lineHeight: "26px", fontWeight: "500" }}>Turn your travel dreams into reality without overspending.  </h6>
            </div>

          </div>
        </div>
        <div className="uspBtn my-3 d-flex justify-content-center align-items-center" >
          <Link to='/visa' style={{ color: "#fff", textDecoration: "none" }} href="#">Check out E-Visa</Link>
        </div>
      </div>
    </div>
  )
}

export default UspBox
