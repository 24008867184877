import React,{useEffect} from 'react'
import { Link,useLocation } from 'react-router-dom'
import ReactGA from 'react-ga';

const PrivacyPol = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.initialize('G-QTRPBPD2RZ'); // Initialize ReactGA with your Measurement ID
        ReactGA.pageview(window.location.pathname + window.location.search); // Track page views

    }, []);
    
    return (
        <div className="container" style={{ backgroundColor: "#fff", padding: "20px 40px", textAlign:"justify"}}>
            <h3 style={{ fontSize: "32px", color: "#000047" }}>Privacy Policy</h3>
            <p>
                Welcome to Tripsy Trips (Sarvang Travels Pvt. Ltd). At Tripsy Trips, we are committed
                to protecting and respecting your privacy.
                This Privacy Policy describes our policies and procedures on the collection, use,
                disclosure, and transfer of Your information by Tripsy Trips and/or its parent/subsidiary
                company and/or affiliate(s) (collectively referred to as “Company” or “we” or “us”) when
                You use our Service This Privacy Policy forms part of the Terms of Use for the
                Services. Capitalized terms that have been used here but are undefined shall have the
                same meaning as attributed to them in the Terms of Use.

                By using the Service, you agree to the collection, storage, and use of the Personal Data
                that you provide (including any changes thereto as provided by you) in accordance with
                this Privacy Policy.
            </p>
            <p>
                DEFINITIONS<br/>

                The capitalized terms shall have the meaning as defined under: - <br/>

                i. Account means a unique account created for You to access our Service or
                parts of our Service.<br/>
                ii. Affiliate means an entity that controls, is controlled by or is under common
                control with a party, where &quot;control&quot; means ownership of 50% or more of the shares,
                equity interest or other securities entitled to vote for election of directors or other
                managing authority.<br/>
                iii. Application means the website (“Site”) and the software program provided by
                the Company and accessed or downloaded by You on any electronic device, named
                Tripsy Trips.<br/>
                iv. Company (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                Agreement) refers to Tripsy Trips (Sarvang Travels Private Limited.)
                1. Country refers to India.
                vi. Device means any device that can access the Service such as a computer, a
                cell phone or a digital tablet.<br/>
                1. Personal Data is any information that relates to an identified or identifiable
                individual.
                viii. Service refers to the Application.<br/>
                1. Service Provider means any natural or legal person who processes the data on
                behalf of the Company. It includes third-party companies or individuals employed
                by the Company to facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service, or to assist the Company in
                analyzing how the Service is used.
                2. Third-party Social Media Service refers to any website or any social network
                website through which a User can log in or create an account to use the Service.
                3. Usage Data refers to data collected automatically, either generated by the use of
                the Service or from the Service infrastructure itself (for example, the duration of a
                page visit).
                4. You mean the individual accessing or using the Service, or the company, or other
                legal entity on behalf of which such individual is accessing or using the Service,
                as applicable.
            </p>
            <p>
                INFORMATION COLLECTED<br/>

                i)           	Personal Data<br/>

                To avail of our Service, you are required to provide us for the registration process and processing of your visa, certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:<br/>
                ●	Email address<br/>
                ●	First name and last name<br/>
                ●	Sex/gender <br/>
                ●	Age/DOB <br/>
                ●	Bank account, credit card or debit card details <br/>
                ●	medical records and history <br/>
                ●	sexual orientation, <br/>
                ●	biometric information <br/>
                ●	password <br/>
                ●	Phone number <br/>
                ●	Address, State, Province, ZIP/Postal code, City <br/>
                ●	Usage Data <br/>
                ●	your occupation, interests, and the like etc. <br/>
                Any personally identifiable information provided by you will not be considered as sensitive if it is freely available and / or accessible in the public domain.
                You have an option to not provide the data or information sought by us.  However, in case you choose to decline to submit Personal data on the Application, we may not be able to provide certain services on the Application to you. We will make reasonable efforts to notify you of the same at the time of registration with us. In any case, we will not be liable and or responsible for the denial of certain services to you for lack of you providing the necessary personal information.
                ii)         	Usage Data

                Usage Data is collected automatically when using the Service. Usage Data may include information such as your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

                When You access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device You use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.

                We may also collect information that your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.

                <br/>USE OF INFORMATION<br/>

                The Company may use the information provided for the following purposes:<br/>
                i.     	To provide and maintain our Service, including to monitor the usage of our Service;<br/>
                ii.     	To manage your registration as a user of the Service. The Personal Data provided can give You access to different functionalities of the Service that are available to You as a registered user;<br/>
                iii.     	For the performance of a contract and to attend and manage and process the requests made by You to us;<br/>
                iv.     	To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation;<br/>
                v.     	To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about or that may be of interest to you. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email we send or by contacting us. We may use Email Marketing Service Providers to manage and send emails to You;<br/>
                vi.     	We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience; and<br/>
                vii.     	We may use your information to conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.<br/>
                INFORMATION SHARING<br/>
                We may share Your personal information with any third party without obtaining your prior consent in the following limited circumstances:<br/>
                i.     	we may share Your personal information with Service Providers to monitor and analyse the use of our Service however it is usually in the form of aggregated statistics on traffic to various pages within our site;<br/>
                ii.     	The Company proposes to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. Further the Service Providers we use may have access to your Personal Data to provide additional services as requested by you among other things. These third-party vendors collect, store, use, process and transfer information about Your activity on our Service in accordance with their Privacy Policies. However, we endeavour that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures;<br/>
                iii.     	In order to process your visa or any other additional request, we share your personal information with the respective government/ diplomatic mission/authorised service provider by that Government. This information includes the personal information supplied by you to support your visa/ permit or travel document application or other service that we provide. We may also share your personal information with the government/ diplomatic mission/authorised service provider by that Government whose visa or any other service you applied for, in connection with a complaint, request or data access request, received from you. We have limited control over the Privacy Policies being used by such Governmental authorities;<br/>
                iv.     	When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offenses. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms or for complying with the applicable laws and regulations;<br/>
                v.     	when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If you interact with other users or register through a Third-Party Social Media Service, your contacts on the Third-Party Social Media Service may see your name, profile, pictures and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with You and view your profile.<br/>
                vi.     	The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:<br/>
                a. 	Protect and defend the rights or property of the Company
                b. 	Prevent or investigate possible wrongdoing in connection with the Service
                c. 	Protect the personal safety of Users of the Service or the public
                d. 	Protect against legal liability

                vii.     	We may disclose Your personal information for any other purpose with your specific prior consent.<br/>

                ACCESSING AND UPDATING PERSONAL INFORMATION<br/>
                When you use the Services, we make good faith efforts to provide you, as and when requested by you, with access to your personal information and shall further ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible, subject to any requirement for such personal information or sensitive personal data or information to be retained by law or for legitimate business purposes. We ask individual users to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others, or would be extremely impractical or for which access is not otherwise required. In any case, where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort.
                In the event you want to cancel your registration or withdraw your consent to providing the Personal data, you may write to us at info@tripsytrips.com. On receipt of such request, the Company shall take reasonable steps to carry out the request within 24 hours of the receipt of the same.
                RETENTION OF INFORMATION

                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Personal Data shall be retained for a period of one hundred and eighty days after any cancellation or withdrawal of the registration, as the case may be.

                The Company will also retain Usage Data and information collected while using the Application for internal analysis purposes. Such information is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.<br/>

                TRANSFER OF YOUR PERSONAL DATA<br/>

                Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

                The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. We endeavour that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.<br/>

                INFORMATION SECURITY<br/>

                We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. All information gathered by the Company is securely stored within the Company controlled database. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.





                <br/> ANALYTICS<br/>

                We may use third-party Service providers to monitor and analyze the use of our Service.

                <br/>PAYMENTS<br/>

                We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g., payment processors). We will not store or collect your payment/card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.

                <br/>LINKS TO OTHER WEBSITES<br/>

                Our Service may contain links to other websites that are not operated by us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

                We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.






                <br/> <Link to="/adminxcv876_@@plm123Qw" style={{color:"#000", textDecoration:"none"}}>UPDATES/CHANGES</Link><br/>

                We may update Our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. Any changes we make will be effective immediately on notice, which we may give by posting the new policy on the Application. Your use of the Services after such notice will be deemed acceptance of such changes.

                <br/>You are advised to review this Privacy Policy periodically for any changes.<br/>



            </p>
        </div>
    )
}

export default PrivacyPol
